
/* RESET */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;

    font-size: calc(16px + 1vh);

    vertical-align:baseline;
    background:transparent;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.Hide {
    display: none;
    color: rgba(0,0,0,0);
    background: rgba(0,0,0,0);
}

/* VIDEO */
#VideoBG {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
}

/* HEADER */
header#BarTop {
    position: fixed;
    top:0%;
    width: 100%;
    height: 50px;
    background: rgba(0,0,0,0);
    color: rgb(255,255,255);
    z-index: 1;
}

footer#BarBottom {
    position: fixed;
    padding: 10px;
    bottom: 0%;
    width: 100%;
    height: 80px;
    z-index: 1;
    background: rgba(0,0,0,0);
    color: rgb(255,255,255);
}

#FooterEdwonLogo {
    position: fixed;
    right: 10px;
    min-height: 30px;
    height: 5%;
    bottom: 10px;
    filter: opacity(65%);
}

#FooterEdwonLogo:hover {
    filter: brightness(1500%);
}

#SocialFollowBar {
    position: fixed;
    left: 10px;
    min-height: 30px;
    height: 5%;
    bottom: 10px;
}

.SocialFollowIcons {
    height: 100%;
    filter: opacity(50%);
    /*filter: saturate(0%);*/
}

.SocialFollowIcons:hover {
    filter: opacity(100%);
}

/* CONTENT */
.Content {
    position: fixed;
    top: 0%;
    height: 100%;
    width: 100%;
    /*background: rgba( 0, 50, 80, .8);*/
    background: linear-gradient(
        rgba(0,50,80,0.7),
        rgba(40,120,160, .8));
    color: #000000; /* text color */
	text-align: center;
    font-family: 'Itim', cursive;
}

.Grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.GridItem {
    margin-left: auto;
    margin-right: auto;
}

#TopGridItem {
    margin: auto;
}

#BottomGridItem {
    margin: auto;
}

#WabaBodyBox {
    /*flex-shrink: 1;*/
    pointer-events: none;
    height: 20%;
}

@keyframes wabaBodyAnim {
    0% {transform: translateY(0px);}
    50% {transform: translateY(-20px);}
    /*100% {transform: translateY(0px);}*/
}

.WabaBody {
    height: 100%;
    -webkit-animation: wabaBodyAnim;
    animation-iteration-count: infinite;
    animation-duration: 4s;
}

#WabaLogoBox {
    height: 15%;
    pointer-events: none;
}

@keyframes wabaLogoAnim {
    0% {transform: translateY(0px);}
    50% {transform: translateY(-20px);}
    100% {transform: translateY(0px);}
}

.WabaLogo {
    height: 100%;
    -webkit-animation: wabaBodyAnim;
    animation-iteration-count: infinite;
    animation-duration: 7s;
}

#CallToAction {
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 50px;
    letter-spacing: 2px;
    font-size: 1em;
    color: #FFFFFF;
}


/* MAIL CHIMP SIGNUP FORM */
#SignupBox {
    margin-top: 30px;
    margin-bottom: 8%;
    width: 95%;
    min-width: 200px;
    max-width: 800px;
}

/*MAIL CHILD EMAIL INPUT*/
#mce-EMAIL {
    font-family: 'Itim', cursive;
    font-size: 1em;
    margin-top: 15px;
    margin-bottom: 15px;
    text-indent: 10px;
    border-width: 0px;
    width: 100%;
    height: 2em;
    border-radius: 50px;
    outline: 0;
    color: rgba(1,1,1, 1);
}

/* MAILCHIMP VALIDATION RESPONSE STYLE*/
.response{
    margin-bottom: 20px;    
}

#mce-error-response {
    font-size: .7em;
    color: rgb(210,210,210);
}

#mce-error-response {
    font-size: .7em;
    color: rgb(240,240,240);
}

#mce-success-response{
    font-size: .7em;
    color: rgb(255,255,255);
}

.mce_inline_error{
    font-size: .7em;
    border-radius: 25px;
}

#SignupFormDescription {
    font-size: 1em;
    color: rgb(255,255,255);
    letter-spacing: 1.5px;
}

/* SIGNUP BUTTON */
.Button {
    font-family: 'Itim', cursive;
    font-size: 1em;
    /*color: rgb(255,255,255);*/
    color: rgb(85,175,200);
    border: 0px;
    width: 100%;
    height: 2em;
    transition: all 0.4s;
    border-radius: 50px;
    /*background: #2E8ACA;*/
    background: rgb(255,255,255);
}

.Button:focus {
    outline:0;
}

.Button:hover {
    /*background: rgb(225,225,225);*/
    /*background: #91D2C8;*/
    transform: scale(1.05);
}

.Button:active {
    /*-webkit-transform: scale(1.3);*/
    background: rgba(0,0,0,0);
    color: rgb(255,255,255);
}

.Button.CTA {
    min-width: 200px;
    max-width: 60%;
}

.Button.CTA.Hide {
    display: none;
    color: rgba(0,0,0,0);
    background: rgba(0,0,0,0);
}

.Button.Submit {
    max-width: 100%;
}

.Button.Submit.Hide {
    transition: .5s;
    color: rgba(0,0,0,0);
    background: rgba(0,0,0,0);
}
